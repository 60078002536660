import React, { useState, useEffect } from "react"
import { css } from "@emotion/react"
import Img from "gatsby-image"
import { breakpoints } from "../components/theme"
import ContentContainer from "../components/contentcontainer"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { Button } from 'react-bootstrap'
import styled from "@emotion/styled";


const ButtonMoreImagesContainer = styled.div`
  text-align: center;
  margin-top: 1rem;
`

const ImageGalleryContainer = styled.div`
  @media (max-width: 767.98px) {
    columns: 2;
    column-gap: 1rem;
  }
  @media (min-width: 768px) {
    columns: 5;
    column-gap: 1rem;
    margin-right: min(5em);
    margin-left: min(5em);
  }
`


export default function Imagegallery(props) {

  // die Funktion erhält in props.imageData die Daten einer graphql Query für Images


  const imageInGalleryStyle = css`
    /* alle für die einzelnen Images notwendigen Styles */
    margin: 0 1rem 1rem 0;
    display: inline-block;
    width: 100%;

    /* FLASHING hover Effekt: bei Mouseover über den einzelnen Bildern - alles Folgende ist nur für den Flashing-Effekt*/
    &:hover {
      opacity: 1;
      -webkit-animation: flash 1.5s;
      animation: flash 1.5s;
    }
    @-webkit-keyframes flash {
      0% {
        opacity: .4;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes flash {
      0% {
        opacity: .4;
      }
      100% {
        opacity: 1;
      }
    }
  `

  const [showImgModal, setShowImgModal] = useState(false);
  const [indexImageToShow, setIndexImageToShow] = useState();
  const [showMobileAllImages, setShowMobileAllImages] = useState(false);

  const handleImgClick = (e, indexImageToShow) => {
    setShowImgModal(true);
    setIndexImageToShow(indexImageToShow);
  }

  const handleCloseClick = () => setShowImgModal(false);






  // ### IS MOBILE DETECTOR ###
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const handleResizeWindow = () => {
      // diese Funktion wird immer dann ausgeführt, wenn das Window geresized wird
      window.innerWidth < breakpoints.md ? setIsMobile(true) : setIsMobile(false)
    }
    // beim ersten Rendern der Component schon die korrete Größe setzen
    window.innerWidth < breakpoints.md ? setIsMobile(true) : setIsMobile(false)
    // subscribe to window resize event "onComponentDidMount"
    // wenn das Window geresized wird, dann führe die Funktion handleResizeWindow aus
    window.addEventListener("resize", handleResizeWindow)
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow)
    }
  }, [])


  // alle Bilder aus den Props extrahieren (um sich Schreibarbeit zu sparen)
  const allImages = props.imageData.edges
  // Index der Images festlegen, die mobile zuerst angezeigt werden sollen
  const indexOfMobileImagesToShow = [0, 1, 2, 3, 6, 7, 8, 9]
  // map über das Array und for each Item in Array, generieren einen neuen Eintrag im Array mobileImages
  const mobileImages = indexOfMobileImagesToShow.map(imageIndex => {
    return props.imageData.edges[imageIndex]
  })



  const Gallery = (props) => {

    return (
      <>
        {props.imagesToShow.map((file, index) => {
          return (
            // aria-hidden="true" muss ergänzt werden, damit die Warnings für "html elments require a role" nicht mehr auftauchen
            <div onClick={(e) => handleImgClick(e, index)} key={file.node.id} css={imageInGalleryStyle} aria-hidden="true">
              {/* Eigentlich müsste hier nur einfach folgendes stehen:
          <Img fluid={file.node.childrenImageSharp[0].fluid}/>
          aber man muss zusätzlich noch die korrekten sizes für srcset angeben.
          Die w-Angaben srcset des <img> tags beziehen sich ja auf den kompletten viewport - hier ist das Layout aber entweder 5-spaltig oder einspaltig.
          Untenstehendes bedeutet:
          - bei einer width-Size kleiner 767.98px ist das img ca. 50%-3em des Viewports breit -> wähle also das passende img aus srcset aus (hier: meist 600px) - ist ja 2-spaltiges Layout;
          - ab einer width-Size von 768px wähle das Bild mit einer Breite von 150px (hier gibt es ein 5-spaltiges Layout). Das kleinstmöglichst in srcset ist 300px, verwende also das (bei 300px benutzt er schon die 600px Auflösung)
          Da die ImageGallery eh nicht breite als 960px wird (begrenzt in layout.js -> max-width), brauchen wir hier auch kein Bild größer 300px
          Achtung Stolperfalle: wenn der Browser einmal eine höhere Auflösung geladen hat, lädt er keine kleinere mehr nach (spart ja dann Traffic)
          Wenn einmal die 600px geladen sind, kommt der Browser also nicht mehr auf die Idee die 300px Auflösung anzuzeigen.
          Zusatzbemerkung: man könnte die sizes auch direkt in der graphql-Query überschreiben; habe ich hier aber nicht gemacht, da die Daten ja von außerhalb der Komponente kommen (bessere Kapselung)
          */}
              <Img
                fluid={{
                  ...file.node.childrenImageSharp[0].fluid,
                  sizes: "(max-width: 767.98px) calc(50vw - 3em), (min-width: 768px) 150px",
                }}
              />
            </div>
          )
        })}
      </>
    )
  }


  return (
    <ContentContainer>

      {/* IMAGE GALLERY */}
      <ImageGalleryContainer>
        {!isMobile && <Gallery imagesToShow={allImages} />}
        {isMobile &&
          <>
            {!showMobileAllImages && <Gallery imagesToShow={mobileImages} />}
            {showMobileAllImages && <Gallery imagesToShow={allImages} />}
          </>
        }
      </ImageGalleryContainer>
      <ButtonMoreImagesContainer>
        {isMobile && !showMobileAllImages && <Button variant="outline-primary" onClick={() => setShowMobileAllImages(true)}>Mehr Bilder</Button>}
        {isMobile && showMobileAllImages && <Button variant="outline-primary" onClick={() => setShowMobileAllImages(false)}>Weniger Bilder</Button>}
      </ButtonMoreImagesContainer>

      {/* IMAGE MODAL */}
      {/* Die Modulo-Berechnungen dienen dem Index-Overflow
      wenn der Index über die maximal-Anzahl der Bilder hinausläuft (also beim letzten Bild auf next geklickt wird)
      würde das für einen Overflow sorgen, da es kein Bild mit dem Index gibt (genauso bei Index 0)
      Die Modulo-Berechnung sorgt dafür, dass beim Überlauf der Index wieder auf 0 gesetzt wird */}
      {showImgModal && (
        <Lightbox
          mainSrc={allImages[indexImageToShow].node.childrenImageSharp[0].fluid.src}
          prevSrc={allImages[(indexImageToShow + allImages.length - 1) % allImages.length].node.childrenImageSharp[0].fluid.src}
          nextSrc={allImages[(indexImageToShow + 1) % allImages.length].node.childrenImageSharp[0].fluid.src}
          onMovePrevRequest={() => setIndexImageToShow((indexImageToShow + allImages.length - 1) % allImages.length)}
          onMoveNextRequest={() => setIndexImageToShow((indexImageToShow + 1) % allImages.length)}
          onCloseRequest={() => handleCloseClick()}
          enableZoom={false}
        />)}

    </ContentContainer>
  )
}